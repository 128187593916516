// import {FaFlask} from "react-icons/fa";
import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";
import styled, {css, withTheme} from "styled-components";

import {themeTypes} from "../theme";
import {wait} from "../utils/wait";
import IconLink from "./IconLink";
// import Button from "./Button";
import Logo from "./Logo";

const Container = styled.div(({isMobileMenuOpen, theme}) => css`
  background-color: ${theme.colors.background};
  display: flex;
  position: fixed;
  opacity: 0;
  z-index: 100;
  flex-direction: column;
  height: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.35s ${theme.animation.easing};
  top: 70px;
  width: 0;
  border: none;
  opacity: 0;
  border-radius: 5px;
  box-shadow: ${
    (theme.type === themeTypes.LIGHT)
      ? "0 8px 18px 0 rgba(0, 0, 0, 0.3)"
      : "0 10px 22px 0 rgba(0, 0, 0, 0.75)"
  };
  overflow: hidden;
  transform: translateY(85px) scale(0.7);
  border: ${
    (theme.type === themeTypes.LIGHT)
      ? "1px solid transparent"
      : `1px solid ${theme.colors.grey[200]}`
  };

  &.is-open {
    opacity: 1;
    transform: translateY(0) scale(1);
    height: calc(100vh - 70px);
    width: 100vw;
    border-radius: 0;
  }

  @media (min-width: 768px) {
    background-color: ${theme.colors.grey[50]};
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    opacity: 1;
    z-index: 100;
    box-shadow: 0 0 0 0 transparent;
    width: 35vw;
    border: none;
    border-right: 1px solid ${theme.colors.grey[200]};
    border-radius: 0;
    top: 0;
    margin: 0;
    transform: none;
    transition: all 0.35s;

    &.is-open {
      height: 100vh;
      transform: none;
    }
  }
`);

const TopSection = styled.header(() => css`
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s 0.35s;
  position: relative;

  &.is-open {
    opacity: 1;
  }

  @media (min-width: 768px) {
    opacity: 1;
    top: -25px;
  }
`);

const SiteName = styled.h1`
  background-color: ${({theme}) => theme.colors.sidebar.siteNameBackground};
  border-radius: 3px;
  color: ${({theme}) => theme.colors.sidebar.siteName};
  font-family: ${({theme}) => theme.fonts.secondary};
  font-size: 13px;
  font-weight: normal;
  padding: 2px 8px;
  text-transform: uppercase;
  transition: background-color 0.2s, color 0.2s;
  margin-top: 15px;

  @media (min-width: 768px) {
    font-size: 13px;
    font-weight: bold;
  }
`;

const Subheading = styled.h4`
  color: ${({theme}) => theme.colors.grey[300]};
  font-family: ${({theme}) => theme.fonts.secondary};
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 3px;
  transition: color 0.2s;

  @media (min-width: 768px) {
    font-size: 10px;
    font-weight: bold;
  }
`;

const Navigation = styled.ul`
  color: ${({theme}) => theme.colors.grey[300]};
  font-family: ${({theme}) => theme.fonts.tertiary};
  font-size: 12px;
  font-weight: bolder;
  text-transform: uppercase;
  list-style: none;
  margin-top: 40px;
  padding: 0;
  text-align: left;
`;

const NavigationItem = styled.li`
  width: 100%;
  transition: border-bottom 0.2s;

  &:last-child {
    border-bottom: none;
  }
`;

const NavigationLink = styled(Link)(({theme, $active}) => css`
  text-decoration: none;
  color: ${theme.colors.grey[300]};
  width: 100%;
  transition: all 0.3s;
  display: inline-block;
  padding: 5px 1px 2px 8px;
  margin: 2px 0;
  border-radius: 4px;
  background: transparent;
  box-shadow: 0 0 0 2px transparent;
  font-size: 13px;
  font-weight: normal;

  @media (min-width: 768px) {
    font-size: 12px;
    font-weight: bold;
  }

  > span {
    position: relative;
    top: 4px;
  }

  &:hover {
    color: ${theme.colors.sidebar.navigationLinkActive};
    background: ${theme.colors.sidebar.navigationLinkHoverBg};
  }

  ${$active && css`
    color: ${theme.colors.sidebar.navigationLinkActive};
    background: ${theme.colors.sidebar.navigationLinkHoverBg};
  `}
`);

const NavigationIcon = styled.div(({theme}) => css`
  font-family: 'Open Sans';
  float: right;
  text-transform: none;
  position: relative;
  margin-right: 3px;
  top: -2px;
  border-radius: 3px;
  background-color: ${theme.type === themeTypes.LIGHT ? "white" : theme.colors.grey[200]};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  span.offset {
    position: relative;
    top: -1px;
  }
`);

const LogoContainer = styled.div(() => css`
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    display: none;
  }
`);

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  font-size: 23px;
  color: ${({theme}) => theme.colors.linkIcon};
  margin-top: -8px;
  margin-bottom: 15px;
  margin-left: 8px;

  @media (min-width: 768px) {
    display: none;
  }
`;


export const Sidebar = withTheme(({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  theme,
}) => {
  const location = useLocation();

  const isInPath = (path) =>
    location.pathname === path || null;

  const onNavigate = async () => {
    await wait(100);
    setIsMobileMenuOpen(false);
  }

  return (
    <Container
      className={clsx({
        "is-open": isMobileMenuOpen,
      })}
    >
      <div>
        <TopSection
          className={clsx({
            "is-open": isMobileMenuOpen,
          })}
        >
          <LogoContainer>
            <Link to="/">
              <Logo size="medium" />
            </Link>
          </LogoContainer>

          <SiteName>Fernando Lujan</SiteName>
          <Subheading>Software &amp; Design</Subheading>

          <SocialLinks>
            <IconLink target="_blank" destination="https://twitter.com/iamvfl">
              <AiOutlineTwitter />
            </IconLink>
            <IconLink target="_blank" destination="https://github.com/fernandolujan">
              <AiFillGithub />
            </IconLink>
            <IconLink target="_blank" destination="https://instagram.com/fernandolujan.ca">
              <AiFillInstagram />
            </IconLink>
            {/* <IconLink target="_blank" destination="https://linkedin.com/in/vfernandolujan">
              <AiFillLinkedin />
            </IconLink> */}
          </SocialLinks>

          <Navigation>
            <NavigationItem>
              <NavigationLink
                $active={isInPath("/")}
                onClick={onNavigate}
                to="/"
              >
                <span>Articles</span>
                <NavigationIcon>&lambda;</NavigationIcon>
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                $active={isInPath("/about")}
                onClick={onNavigate}
                to="/about"
              >
                <span>About</span>
                <NavigationIcon>&Phi;</NavigationIcon>
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                $active={isInPath("/work")}
                onClick={onNavigate}
                to="/work"
              >
                <span>Work</span>
                <NavigationIcon>&Delta;</NavigationIcon>
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                $active={isInPath("/contact")}
                onClick={onNavigate}
                to="/contact"
              >
                <span>Contact</span>
                <NavigationIcon>
                  <span className="offset">@</span>
                </NavigationIcon>
              </NavigationLink>
            </NavigationItem>
          </Navigation>
        </TopSection>
      </div>
    </Container>
  )
});

export default Sidebar;
