import {createBrowserHistory} from 'history';
import {Provider} from "react-redux"
import {Router} from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';

import {store} from './store';
import Application from './components/Application';
import reportWebVitals from './reportWebVitals';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Application />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();