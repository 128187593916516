import {Helmet} from "react-helmet";

import {
  RolesTimeline,
  TimeframeLabel,
  CompanyName,
  CompanyPhoto,
  CompanyPhotoImgContainer,
  CompanyPhotoImg,
  ContractDecorator,
  RoleTimelineEntryContainer,
} from "./styled-components";

const RoleTimelineEntry = ({
  company,
  contract,
  timeframe,
  role,
  logoUrl,
  link,
  noPad,
}) => {
  const onClick = () => {
    if (link) {
      window.open(link, '_blank').focus();
    }
  };

  return (
    <RoleTimelineEntryContainer onClick={onClick}>
      <div>
        <CompanyPhoto>
          <CompanyPhotoImgContainer>
            <CompanyPhotoImg src={logoUrl} $noPad={noPad} />
          </CompanyPhotoImgContainer>
        </CompanyPhoto>
      </div>
      <div>
        <TimeframeLabel>{timeframe}</TimeframeLabel>
        <CompanyName>{company}</CompanyName>
        <div>
          {role}
          {" "}
          {contract && (
            <ContractDecorator>
              (contract)
            </ContractDecorator>
          )}
        </div>
      </div>
    </RoleTimelineEntryContainer>
  );
};

export const Work = () => {
  return (
    <div>
      <Helmet>
        <title>Fernando Lujan | Work</title>
        <link rel="canonical" href={`https://fernandolujan.ca/work`} />
        <meta name="twitter:title" content="Work" />
        <meta name="twitter:description" content="View Fernando's work experience through past positions" />
        <meta property="og:title" content="Work" />
        <meta property="og:description" content="View Fernando's work experience through past positions" />
      </Helmet>
      <h2>Work</h2>
      <RolesTimeline>
        <RoleTimelineEntry
          company="Kuva Systems"
          logoUrl="/assets/logos/kuva-systems.png"
          link="https://kuvasystems.com"
          role="Software Engineer"
          timeframe="Jan. 2023  - Present"
          noPad
        />
        <RoleTimelineEntry
          company="Ambyint"
          logoUrl="/assets/logos/ambyint.webp"
          link="https://ambyint.com"
          role="Sr. Full-Stack Developer"
          timeframe="Jan. 2019 - Dec. 2022 (4 yrs)"
        />
        <RoleTimelineEntry
          company="McCann"
          logoUrl="/assets/logos/mccann.png"
          link="https://mccann.ca"
          role="Senior Developer"
          timeframe="May 2018 – Sept. 2018"
          contract
        />
        <RoleTimelineEntry
          company="Critical Mass"
          logoUrl="/assets/logos/critical-mass.png"
          link="https://criticalmass.com"
          role="Senior Developer"
          timeframe="Sept. 2017 – Apr. 2018"
          contract
        />
        <RoleTimelineEntry
          company="Axia"
          logoUrl="/assets/logos/axia.webp"
          link="https://www.axia.com"
          role="Software Engineer"
          timeframe="Dec. 2014 – Aug. 2016"
          contract
        />
        <RoleTimelineEntry
          company="Quadrus"
          logoUrl="/assets/logos/quadrus.jpeg"
          link="https://quadrus.com"
          role="Software Developer"
          timeframe="Oct. 2014 – Feb. 2016"
        />
        <RoleTimelineEntry
          company="WestJet"
          logoUrl="/assets/logos/westjet.png"
          link="https://westjet.com"
          role="Jr. Software Developer"
          timeframe="May 2013 – Jul. 2014"
          contract
        />
        <RoleTimelineEntry
          company="Essel Solutions"
          logoUrl="/assets/logos/essel.png"
          role="Founder, Web Developer"
          timeframe="Jul. 2010 – May 2013"
        />
      </RolesTimeline>
    </div>
  );
};
