import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import {Link} from "react-router-dom";
import {Spin as MobileMenuButton} from "hamburger-react";
import styled, {css, withTheme} from "styled-components";

import Footer from "./Footer";
import IconLink from "./IconLink";
import Logo from "./Logo";
import Router from "../routes/Router";
import ThemeToggleSwitch from "./ThemeToggleSwitch";

const Container = styled.div`
  margin-left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.header(({theme}) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border-bottom 0.2s;
  padding: 12px 18px 10px 20px;
  border-bottom: 1px solid ${({theme}) => theme.colors.grey[200]};
  margin-bottom: 20px;
  background-color: ${theme.colors.background};
  z-index: 10;
  transition: all 0.2s;

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
  }
`);

const LeftColumn = styled.div(() => css`
  display: flex;
`);

const SocialLinks = styled.div`
  display: none;
  align-items: center;
  font-size: 23px;
  color: ${({theme}) => theme.colors.linkIcon};
  margin-top: -8px;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const PageControls = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.main`
  padding: 12px 28px;

  @media (min-width: 768px) {
    padding: 20px 50px;
  }
`;

const ContentContainer = styled.div`
  min-height: calc(100vh - 253px);
`;

const MobileNavigationContainer = styled.div(() => css`
  margin-right: 15px;
  display: flex;
  align-items: center;
  
  @media (min-width: 768px) {
    display: none;
  }
`);

export const MainContent = withTheme(({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  selectedTheme,
  theme,
  toggleTheme,
}) => (
  <Container>
    <Header>
      <LeftColumn>
        <MobileNavigationContainer>
          <MobileMenuButton
            toggled={isMobileMenuOpen}
            toggle={setIsMobileMenuOpen}
            size={24}
            color={theme.colors.linkIcon}
            label="Show navigation menu"
            hideOutline
            rounded
          />
          <Link to="/">
            <Logo color={theme.colors.primary} size="small" />
          </Link>
        </MobileNavigationContainer>
        <SocialLinks>
          <IconLink target="_blank" destination="https://twitter.com/iamvfl">
            <AiOutlineTwitter />
          </IconLink>
          <IconLink target="_blank" destination="https://github.com/fernandolujan">
            <AiFillGithub />
          </IconLink>
          <IconLink target="_blank" destination="https://instagram.com/fernandolujan.ca">
            <AiFillInstagram />
          </IconLink>
          {/* <IconLink target="_blank" destination="https://linkedin.com/in/vfernandolujan">
            <AiFillLinkedin />
          </IconLink> */}
        </SocialLinks>
      </LeftColumn>

      <PageControls>
        <ThemeToggleSwitch selectedTheme={selectedTheme} toggleTheme={toggleTheme} />
      </PageControls>
    </Header>
    <Content>
      <ContentContainer>
        <Router />
      </ContentContainer>
      <Footer />
    </Content>
  </Container>
));

export default MainContent;
